// Libraries import
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useState } from "react";
import { FileRejection, FileWithPath } from "react-dropzone";
pdfMake.vfs = pdfFonts.pdfMake?.vfs;

// Interfaces import
import { IUploadFileData } from "../../interfaces/interfaces";

// Local interfaces declaration
interface IFunctionReturn {
  formatUploadedRejectedFile: (
    file: FileRejection,
    userId: string | null,
    setUploadedFile: React.Dispatch<React.SetStateAction<IUploadFileData[]>>
  ) => void;
  formatUploadedAcceptedFile: (
    file: FileWithPath,
    userId: string | null,
    setUploadedFile: React.Dispatch<React.SetStateAction<IUploadFileData[]>>
  ) => void;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

// useFormatUploadedFile : return tools to format the uploaded document in the adapted format
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function formatUploadedAcceptedFile
//     Function to format the files that are considered as valid (see dropzone librarie)
// Function formatUploadedRejectedFile
//     Function to format the files that are considered as invalid (see dropzone librarie)

export const useFormatUploadedFile = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  // formatUploadedAcceptedFile : save accepted file in a states in a pdf format
  // Parameters
  // ----------
  // file: FileWithPath
  //      the accepted uploaded file
  // userId: String | null
  //      the id of the user
  // Returns
  // ----------
  // void
  const formatUploadedAcceptedFile = (
    file: FileWithPath,
    userId: string | null,
    setUploadedFile: React.Dispatch<React.SetStateAction<IUploadFileData[]>>
  ): void => {
    setErrorMessage("");

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      if (event.target?.result) {
        // If the file uploaded is an image, we turn it into a pdf
        if (file.type === "image/jpeg" || file.type === "image/png") {
          const docDefinition = {
            content: [
              // pdfmake (since it\'s based on pdfkit) supports JPEG and PNG format,
              // If no width/height/fit is provided, image original size will be used,
              {
                image: event.target.result.toString(),
                width: 510,
              },
            ],
          };
          const pdfDocGenerator = pdfMake.createPdf(docDefinition);

          // then we save it in base64 and Blob format
          pdfDocGenerator.getDataUrl((fileContentBase64) => {
            pdfDocGenerator.getBlob((fileContentBlob) => {
              const formatedFile: IUploadFileData = {
                userId,
                fileName: file.name,
                fileType: "application/pdf",
                fileSize: file.size,
                fileContentBase64: fileContentBase64,
                fileContentBlob: fileContentBlob,
              };
              setUploadedFile((state) => {
                const newsState = [...state];
                newsState.push(formatedFile);
                return newsState;
              });
            });
          });
        } else {
          // If the file uploaded is a pdf we directly save it in base64 and Blob format
          const formatedFile: IUploadFileData = {
            userId,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
            fileContentBase64: event.target.result,
            fileContentBlob: file,
          };
          setUploadedFile((state) => {
            const newsState = [...state];
            newsState.push(formatedFile);
            return newsState;
          });
        }
      }
    };
  };

  // formatUploadedRejectedFile : save rejected file in a states in the right format
  // Parameters
  // ----------
  // file: FileRejection
  //      the rejected uploaded file
  // userId: String | null
  //      the id of the user
  // Returns
  // ----------
  // void

  const formatUploadedRejectedFile = (
    file: FileRejection,
    userId: string | null,
    setUploadedFile: React.Dispatch<React.SetStateAction<IUploadFileData[]>>
  ): void => {
    setErrorMessage("");
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = (event) => {
      if (event.target?.result) {
        const formatedFile: IUploadFileData = {
          userId,
          fileName: file.file.name,
          fileType: file.file.type,
          fileSize: file.file.size,
          fileContentBase64: event.target.result,
        };
        setErrorMessage(file.errors[0].message);
        setUploadedFile((state) => {
          const newsState = [...state];
          newsState.push(formatedFile);
          return newsState;
        });
      }
    };
  };
  return {
    formatUploadedAcceptedFile,
    formatUploadedRejectedFile,
    errorMessage,
    setErrorMessage,
  };
};
